<template>
	<div id="register-main" class="auth-container">
		<div class="auth-container__user-select-wrapper">
			<AnimatedLogoPreloader :style="{marginTop: isMobile ? '10px' : '40px'}"></AnimatedLogoPreloader>

			<div class="register-options" :style="{marginTop: isMobile ? '10px' : '20px'}"
				 v-if="!isTrainerRegister && !isOwnerRegister && !isMemberWithoutReflexRegister && !isMemberWithReflexRegister && !isInvite">

				<div class="registration-cards" id="feature-cards" style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap; gap: 20px;">
					<div v-for="(plan, index) in pricePlans" :key="index" class="card-container" style="display: flex; flex-direction: column; align-items: center; text-align: center;">

						<div class="card-top" style="display: flex; text-align: center; margin-top: 10px;">
							<div class="figtree-bold-white-32px smaller-font"
									 :style="{fontSize: isMobile ? '25px' : '31px'}">
								{{ plan.title }}
							</div>
<!--							<div class="figtree-medium-bombay-14px smaller-font"-->
<!--									 :style="{fontSize: isMobile ? '12px' : ''}">-->
<!--								{{ plan.description }}-->
<!--							</div>-->
						</div>

						<div class="card-center" style="display: flex; flex-direction: column; align-items: baseline; justify-content: center;">
							<md-button v-if="plan.title === 'Individual'"
												 class="md-primary md-raised form-select-btn md-theme-light try-btn figtree-medium-white-20px"
												 :style="{fontSize: isMobile ? '15px' : '', height: '40px'}"
												 @click="handlePlanSelectionForTrainer('monthly')">
								$ 9.99 / month
							</md-button>
							<div :style=" {height: '10px'}"></div>
							<md-button v-if="plan.title === 'Individual'"
												 class="md-primary md-raised form-select-btn md-theme-light try-btn figtree-medium-white-20px"
												 :style="{fontSize: isMobile ? '15px' : '', height: '85px'}"
												 @click="handlePlanSelectionForTrainer('yearly')">
								<div style="display: flex; flex-direction: column; align-items: center;">
									<span style="font-size: 16px; font-weight: bold;">$ 4.99 / month</span>
									<span style="font-size: 14px; color: white; text-decoration: line-through;">$ 9.99 / month</span>
									<span style="font-size: 12px; color: white; font-weight: bold; background-color: black; padding: 2px 6px; border-radius: 5px;">Save 50%</span>
									<span style="font-size: 14px; color: white;">Billed annually: $58.88</span>
								</div>
							</md-button>

							<md-button v-if="plan.title === 'Family Plan'"
												 class="md-primary md-raised form-select-btn md-theme-light try-btn figtree-medium-white-20px"
												 :style="{fontSize: isMobile ? '15px' : '', height: '40px'}"
												 @click="handlePlanSelectionForOwner('monthly')">
								$ 24.99 / month
							</md-button>
							<div :style=" {height: '10px'}"></div>
							<md-button v-if="plan.title === 'Family Plan'"
												 class="md-primary md-raised form-select-btn md-theme-light try-btn figtree-medium-white-20px"
												 :style="{fontSize: isMobile ? '15px' : '', height: '85px'}"
												 @click="handlePlanSelectionForOwner('yearly')">
								<div style="display: flex; flex-direction: column; align-items: center;">
									<span style="font-size: 16px; font-weight: bold;">$ 14.99 / month</span>
									<span style="font-size: 14px; color: white; text-decoration: line-through;">$ 24.99 / month</span>
									<span style="font-size: 12px; color: white; font-weight: bold; background-color: black; padding: 2px 6px; border-radius: 5px;">Save 40%</span>
									<span style="font-size: 14px; color: white;">Billed annually: $179.88</span>
								</div>
							</md-button>
						</div>

						<div class="card-bottom" style="display: flex; flex-direction: column; gap: 10px; align-items: start;">
							<div v-for="(feature, index) in plan.infoFeatures" :key="index" class="info" style="flex: 10;">
                <span class="figtree-medium-bombay-16px">
                    <img style="width: 24px; height: 24px;" src="@/assets/images/check-circle-fill.png" alt="info"> {{ feature.text }}
                </span>
							</div>

							<div v-if="!isMobile" style="opacity: 0;">1</div>

						</div>
						<div style="text-align: center; display: flex; flex-direction: column; gap: 5px;">
							<div class="figtree-normal-white-16px" style="flex: 40;">
								<div class="figtree-normal-white-18px">
									First 2 Weeks Free
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="auth-container__form-wrap__form-description">
    				<span>Already have an Account? </span>
					<span class="back-to-login-page" @click="$router.push('/login');">Sign In</span>
  				</p>

<!--				<p class="auth-container__form-wrap__form-description">-->
<!--					Are you a gym member looking to connect with your gym that already subscribes to Reflex Radio?-->
<!--				</p>-->
<!--				<md-button-->
<!--					class="md-primary md-raised form-select-btn md-theme-light"-->
<!--					@click="isMemberWithReflexRegister = true"-->
<!--				>-->
<!--					CLICK HERE TO CONNECT-->
<!--				</md-button>-->

<!--				<p class="auth-container__form-wrap__form-description">-->
<!--					Want Reflex Radio for your workout? Let us know if you want us to contact your gym!-->
<!--				</p>-->
<!--				<md-button-->
<!--					class="md-primary md-raised form-select-btn md-theme-light"-->
<!--					@click="isMemberWithoutReflexRegister = true"-->
<!--				>-->
<!--					CLICK HERE TO PROVIDE DETAILS-->
<!--				</md-button>-->
			</div>

			<AuthRegisterFormTrainer
					:is-register-prop.sync="isTrainerRegister"
					@register="register"
					:selected-plan="selectedPlan"
			/>

			<AuthRegisterOwner
				:is-register-prop.sync="isOwnerRegister"
				@register="register"
				:selected-plan="selectedPlan"
			/>

			<AuthRegisterFormMember
				v-if="isInvite || isMemberWithReflexRegister"
				:is-register-prop.sync="isMemberWithReflexRegister"
				@register="register"
				:reg-form-prop="regForm"
				:is-invite-prop="isInvite"
			/>

			<AuthRegisterFormMemberWithoutStore
				:is-register-prop.sync="isMemberWithoutReflexRegister"
				@register="registerMemberWithoutStore"
			/>
		</div>

	</div>
</template>

<script>
	import AnimatedLogoPreloader from '@/components/Common/AnimatedLogoPreloader';
	import {statesConstData} from '@/constants'
	import AuthRegisterFormTrainer from "@/components/Auth/AuthRegisterFormTrainer";
	import AuthRegisterOwner from "@/components/Auth/AuthRegisterFormOwner";
	import AuthRegisterFormMember from "@/components/Auth/AuthRegisterFormMember";
	import AuthRegisterFormMemberWithoutStore from "@/components/Auth/AuthRegisterFormMemberWithoutStore";
	import {CheckWindowWidthMixin} from '../../mixins/CheckWindowWidthMixin';

	export default {
		name: 'AuthRegister',
		mixins: [CheckWindowWidthMixin],
		data: () => ({

			regForm: {
				first_name: null,
				last_name: null,
				email: null,
				password: null
			},
			showPhotoError: false,
			isInvite: false,
			previewUrl: '',
			states: statesConstData,
			isTrainerRegister: false,
			selectedPlan: 'monthly',
			isOwnerRegister: false,
			isMemberWithReflexRegister: false,
			isMemberWithoutReflexRegister: false,
			pricePlans: [
				{
					title: 'Individual',
					// price: 19.99,
					infoFeatures: [
						{text: '1 User Account'},
						{text: 'Unlimited Blueprint Creation'},
						// {text: 'Unlimited Member/Follower Accounts'}
					],
					// description: 'Perfect for individual users'
				},
				{
					title: 'Family Plan',
					infoFeatures: [
						{text: 'Five User Accounts'},
						{text: 'Unlimited Blueprint Creation'},
						// {text: 'Unlimited Member/Follower Accounts'}
					],
					// description: 'Your entire team in one place'
				},
			]
		}),

		components: {
			AnimatedLogoPreloader,
			AuthRegisterFormMemberWithoutStore,
			AuthRegisterFormMember,
			AuthRegisterOwner,
			AuthRegisterFormTrainer,
		},

		methods: {

			login(user) {

				this.$store.dispatch('auth/loginAfterRegistration', {
					email: user.email,
					password: user.password,
				})
					.then((res) => {

						//Set axios Authorization header
						this.$axios.defaults.headers.common['Authorization'] = `Bearer ${res.auth_token}`;
						this.$router.push('/dashboard');
					})
					.catch((error) => {

						console.log(error);
						this.$store.dispatch('showSnackbarMessage',
							{message: error.response.data, duration: 4000, mode: 'fail'});
					});
			},

			registerMemberWithoutStore(user) {

				const path = 'store-requests';

				this.$axios.post(path, user)
					.then(res => {

						this.isMemberWithoutReflexRegister = false;
						this.$store.dispatch('showSnackbarMessage',
							{message: 'Your information was sent successfully.', duration: 4000, mode: 'success'});
					})
					.catch(error => {

						this.$store.dispatch('showSnackbarMessage',
							{message: error.response.data, duration: 4000, mode: 'fail'});
					});
			},

			// Plan selection logic
			handlePlanSelectionForOwner(planType) {
				this.selectedPlan = planType; // Set the selected plan (either 'monthly' or 'yearly')
				this.isOwnerRegister = true; // Show the registration form
			},
			// Plan selection logic
			handlePlanSelectionForTrainer(planType) {
				this.selectedPlan = planType; // Set the selected plan (either 'monthly' or 'yearly')
				this.isTrainerRegister = true; // Show the registration form
			},
			// Handle user registration
			register(user) {

				// let user = this.setUserForRegister();

				let path = 'register';
				if (this.token) path += '/' + this.token;

				this.$axios.post(path, user)
					.then(res => {
						// console.log(res);
						// this.$router.replace('/login');

						this.$store.dispatch('showSnackbarMessage',
							{message: 'Registered successfully.', duration: 4000, mode: 'success'});
						this.login(user);
					})
					.catch(error => {

						this.$store.dispatch('showSnackbarMessage',
							{message: error.response.data, duration: 4000, mode: 'fail'});
					});
			},

			getTokenInvitation(token) {

				this.$axios.get(`/invites/${token}`).then(res => {

					this.isInvite = true;
					this.regForm.first_name = res.data.first_name;
					this.regForm.last_name = res.data.last_name;
					this.regForm.email = res.data.email;
				}).catch(error => {

					this.$store.dispatch('showSnackbarMessage',
						{message: error.response.data, duration: 4000, mode: 'fail'});
				});
			},
		},

		computed: {},

		created() {

			if (this.$route.params.token) {

				this.isCreateAccount = false;
				this.token = this.$route.params.token;

				this.getTokenInvitation(this.token);
			}
		},
	};
</script>

<style lang="scss" scoped>

	@include authPage;

	.logo-image {
		margin-bottom: 2.3%;
	}

	.single-trainer-checkbox {
		color: #FFFFFF;
	}
	.registration-cards{
		display: flex;
		justify-content: center;
		gap:40px;
		flex-wrap: wrap;
		@media only screen and (max-width: 481px) {
			gap: 10px;
		};
	}
	.card-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 25px 25px;
		gap: 20px;
		background: #28292E;
		border: 1px solid #3C3C3C;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		height: 500px;
	    width: 350px;
		@media only screen and (max-width: 834px) {
			height: 480px;
		};
		@media only screen and (max-width: 481px) {
			height: 450px;
			gap: 5px;

		};

		.card-top {
			flex: 15;
			display: flex;
			flex-direction: column;
			gap:10px;
			align-items: center;
			justify-content: center;
			width: 100%;
		}

		.card-center {
			flex:15;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}
		.price{
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			gap: 10px;
		}
		.card-bottom {
			flex:20;
			gap: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			text-align: left;
			padding-bottom: 5px;

			@include respond(small) {
				font-size: 1.2rem;
			}
		}
	}
	.try-btn{
		width: 237px;
		height: 60px;
		border-radius: 5px;
	}
	.back-to-login-page{
		color: #BB6BD9;;
		cursor: pointer;
	}
	.back-to-login-page:hover{
		color: #FFFFFF;;
	}
</style>
